import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Container} from "aurelia-dependency-injection"
import {CurrencyValueConverter} from "../../currency/currency-value-converter"
import {CurrencyInput, DateSelect, EntityPickerWithSelect, FormItem} from "../../form/rsuite-form"
import {AccountSelect, PeriodSelect, TAX} from "./form-elements"
import {selectBooking, selectErrors, setBooking} from "../store/editor-slice"
import {selectOrganization} from "../store/entries-slice"
import {
    Col, ControlLabel, Divider, FlexboxGrid, Form, FormControl, FormGroup,
    Icon, IconButton, InputPicker
} from "rsuite"

const currencyConverter = Container.instance.get(CurrencyValueConverter);

export default function EntryEditorComplex() {
    const dispatch = useDispatch()
    const booking = useSelector(selectBooking)
    const errors = useSelector(selectErrors)
    const organization = useSelector(selectOrganization)
    const {parts, bookDate, receiptDate, receiptNumber, period, tax, subject, costObject, reference} = booking

    const onChange = ({bookDate, receiptDate, receiptNumber, tax, subject, costObject, reference}) => {
        dispatch(setBooking({
            ...booking,
            bookDate: bookDate.toISOString(),
            receiptDate: receiptDate?.toISOString(),
            receiptNumber,
            tax,
            subject,
            costObject,
            reference,
            parts
        }));
    };

    const onChangePart = key => ({account: {id, currency, $sort}, dr, cr}) => {
        const newParts = [...parts]
        newParts[key] = {
            account: {id, modelId: "accounting/ledger-account", currency: currency ?? $sort}
        };
        if (undefined !== dr?.amount && !cr?.amount) {
            newParts[key].dr = dr.amount;
            newParts[key].cr = null;
        } else {
            newParts[key].cr = cr?.amount ?? 0
            newParts[key].dr = null;
        }
        dispatch(setBooking({...booking, parts: newParts}));
    };

    const addPart = () => {
        const newParts = [...parts]
        newParts.push({account: {}});
        dispatch(setBooking({...booking, parts: newParts}))
    };

    const removePart = key => () => {
        const newParts = [...parts]
        newParts.splice(key, 1);
        setBooking({...booking, parts: newParts});
    }

    const formValue = {
        receiptNumber, period, tax, subject, costObject, reference,
        bookDate: new Date(bookDate),
        receiptDate: new Date(receiptDate)
    };

    const formError = !errors ? undefined : {parts: parts.map(() => ({}))};

    if (errors) {
        for (const field of ["bookDate", "receiptDate", "receiptNumber", "tax", "subject", "costObject", "reference"]) {
            if (errors[field]?.errors) {
                formError[field] = errors[field].errors.join(" ");
            }
        }
        parts.forEach(
            (_, key) => Object.entries(errors.parts.children[key]?.children ?? {}).forEach(
                ([field, {errors}]) => Array.isArray(errors) && (
                    formError.parts[key][field] = errors.join(" ")
                )
            )
        );
    }

    const drSums = new Map;
    const crSums = new Map;

    return (
        <FlexboxGrid>
            <Form fluid formValue={formValue} formError={formError} onChange={onChange}>
                <FormItem field="bookDate" label="Buchdatum" accepter={DateSelect} cleanable={false} col={{xs: 6, lg: 4}}/>
                <FormItem field="receiptDate" label="Belegdatum" accepter={DateSelect} cleanable={false} col={{xs: 6, lg: 4}}/>
                <FormItem field="receiptNumber" label="Belegnr." col={{xs: 6, lg: 4}}/>
                {/*<FormItem field="period" label="Geschäftsjahr" accepter={PeriodSelect} organization={organization}/>*/}
                <FormItem field="tax" label="Steuer" accepter={InputPicker} data={TAX} cleanable={false} block col={{xs: 6, lg: 4}}/>
                <FormItem field="subject" label="Betreff" col={{xs: 24, md: 12, lg: 8}}/>
                <FormItem field="costObject" label="Kostenträger" accepter={EntityPickerWithSelect}
                          modelId="accounting/cost-object"  col={{xs: 12, md: 6, lg: 4}}/>
                <FormItem field="reference" label="Referenz" accepter={EntityPickerWithSelect}
                          modelId="accounting/booking-reference"  col={{xs: 12, md: 6, lg: 4}}/>
            </Form>

            <FlexboxGrid.Item colspan={24}>
                <Divider/>
                <PartRow>
                    <PartCol>Konto</PartCol>
                    <PartCol>Haben</PartCol>
                    <PartCol>Soll</PartCol>
                </PartRow>
                {parts.map(({account, cr, dr}, key) => {
                    const currency = account?.currency ?? "EUR";
                    const formValue = {
                        account: account ? {id: account.id, currency, modelId: "accounting/ledger-account"} : null,
                        dr: {amount: dr, currency},
                        cr: {amount: cr, currency}
                    };

                    if (undefined !== dr && null !== dr) {
                        drSums.set(currency, dr + (drSums.get(currency) ?? 0));
                    }
                    if (undefined !== cr && null !== cr) {
                        crSums.set(currency, cr + (crSums.get(currency) ?? 0));
                    }

                    return (
                        <Form fluid formValue={formValue} formError={formError?.parts[key]} key={key} onChange={onChangePart(key)}>
                            <PartRow>
                                <SplitFormItem field="account" label="Konto" accepter={AccountSelect} organization={organization}/>
                                <SplitFormItem field="dr" label="Haben" accepter={CurrencyInput}/>
                                <SplitFormItem field="cr" label="Soll" accepter={CurrencyInput}/>
                                {3 > parts.length ? null : (
                                    <PartCol>
                                        <IconButton appearance="ghost" size="xs" icon={<Icon icon="close"/>} onClick={removePart(key)}/>
                                    </PartCol>
                                )}
                            </PartRow>
                        </Form>
                    );
                })}
                <PartRow>
                    <PartCol>
                        <IconButton appearance="ghost" size="xs" icon={<Icon icon="plus"/>} onClick={event => addPart(event)}/>
                    </PartCol>
                    <PartCol className="text-right">
                        {Array.from(drSums.entries()).map(([currency, amount]) => currencyConverter.toView({
                            amount,
                            currency
                        }))}
                    </PartCol>
                    <PartCol className="text-right">
                        {Array.from(crSums.entries()).map(([currency, amount]) => currencyConverter.toView({
                            amount,
                            currency
                        }))}
                    </PartCol>
                </PartRow>
            </FlexboxGrid.Item>
        </FlexboxGrid>
    )
}

const PartRow = ({children}) => (
    <FlexboxGrid align="middle" style={{marginBottom: ".5rem"}}>
        {children}
    </FlexboxGrid>
)

const PartCol = ({className, children}) => (
    <FlexboxGrid.Item componentClass={Col} xs={8} md={6} lg={4} className={className}>
        {children}
    </FlexboxGrid.Item>
)

const SplitFormItem = ({label, field, accepter, ...props}) => (
    <PartCol>
        <FormGroup controlId={field}>
            <ControlLabel srOnly={true}>{label}</ControlLabel>
            <FormControl name={field} accepter={accepter} {...props}/>
        </FormGroup>
    </PartCol>
)
