import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Icon, IconButton} from "rsuite"
import {newBooking, selectEditorDrawer} from "../store/editor-slice"
import {loadAccountingBooking} from "../store/accounting-api"
import {selectOrganization, selectStack} from "../store/entries-slice"
import {selectCurrentPeriod} from "../store/state-slice"

export default function EditButton({id, icon = "edit2", label = "Bearbeiten", ...props}) {
    const dispatch = useDispatch()
    const [open] = useSelector(selectEditorDrawer)
    const organization = useSelector(selectOrganization)
    const stack = useSelector(selectStack)
    const period = useSelector(selectCurrentPeriod(organization))

    function handleClick() {
        dispatch(id ?
            loadAccountingBooking(id, {forceRefetch: true}) :
            newBooking({organization, period, stack})
        )
    }

    return (
        <IconButton
            size="sm" color="red" disabled={open}
            onClick={() => handleClick()}
            icon={<Icon icon={icon}/>}
            {...props}>

            {label}
        </IconButton>
    )
}
