import React from "react"
import moment from "moment-timezone"
import {ButtonGroup, Col, FlexboxGrid, Icon} from "rsuite"
import StatsLabel from "../../statistics/time-aggregation/stats-label"
import {Actions} from "../ledger/action"
import {Container} from "aurelia-dependency-injection";
import {selectAccountingStates} from "../store/state-slice";
import {useSelector} from "react-redux";
import {CurrencyValueConverter} from "../../currency/currency-value-converter";
import EditButton from "./edit-button";

const currency = Container.instance.get(CurrencyValueConverter)

export const EntryDetails = ({booking}) => {
    const states = useSelector(selectAccountingStates)

    const {booking: id, reference, costObject, period, bookSequence, stack, stackName, system} = booking
    const state = states[booking.state]
    const details = [
        ["Buchdatum", moment(booking.bookDate).format("L")],
        ["Betreff", booking.subject],
        ["Belegdatum", moment(booking.receiptDate).format("L")],
        ["Belegnummer", booking.receiptNumber],
        ["Belegnummer 2", booking.receiptNumber2],
        ["Referenz", reference && <StatsLabel label={reference}/>],
        ["Kostenträger", costObject && <StatsLabel label={costObject}/>],
        ["Periode", period && (
            <>
                <DetailLink id={period} label={booking.periodLabel} modelId="accounting/period"/>
                {" / " + booking.subperiod}
            </>
        )],
        ["Status", (
            <>
                <Icon icon={state.icon}/>
                {" " + state.label + (bookSequence ? ", Buchungsjournalnr. " + bookSequence : "")}
            </>
        )],
        ["Transaktion", id],
        ["Buchungsart", system ? "Automatisch gebucht" : "Manuell gebucht"],
    ];

    if (stack) {
        details.push(["Buchungsstapel", <DetailLink id={stack} label={stackName} modelId="accounting/stack"/>])
    }

    for (const entry of booking.datevAdditionalFields ?? []) {
        details.push(entry)
    }

    for (const {automatically, bookSequences, clearedAt, clearedBy} of booking.cleared ?? []) {
        details.push(["Ausgeziffert", (
            <>
                {automatically && 'automatisch '}
                {`gegen Journal-Nr. ${bookSequences.filter(s => s !== bookSequence).join(", ")} `}
                {`am ${moment(clearedAt).format("L LT")}`}
                {clearedBy && <>{" von "}<StatsLabel label={clearedBy}/></>}
            </>
        )])
    }

    return (
        <FlexboxGrid align="top" justify="space-between">
            <GridItem xs={24} sm={12}>
                <FlexboxGrid>
                    {details
                        .filter(([_, value]) => value && ("string" !== typeof value || value.length))
                        .map(([label, value], key) => (
                            <React.Fragment key={key}>
                                <GridItem xs={8} sm={6}>{label}</GridItem>
                                <GridItem xs={16} sm={18}>{value}</GridItem>
                            </React.Fragment>
                        ))
                    }
                </FlexboxGrid>
            </GridItem>

            <GridItem xs={24} sm={12}>
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <FlexboxGrid justify="space-between">
                            <GridItem xs={4}>Soll</GridItem>
                            <GridItem xs={4}>Haben</GridItem>
                            <GridItem xs={4}>Konto</GridItem>
                            <GridItem xs={12}/>

                            {booking.parts.map(({dr, cr, account: id, accountCode: code, accountName}, idx) => (
                                <React.Fragment key={idx}>
                                    <GridItem xs={4}>{dr && currency.toView(dr)}</GridItem>
                                    <GridItem xs={4}>{cr && currency.toView(cr)}</GridItem>
                                    <GridItem xs={4}><DetailLink id={id} label={code}/></GridItem>
                                    <GridItem xs={12}>{accountName}</GridItem>
                                </React.Fragment>
                            ))}
                        </FlexboxGrid>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item>
                        <ButtonGroup size="xs">
                            <Actions id={id} state={state} system={system}/>
                            {state.canEdit && <EditButton id={id}/>}
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </GridItem>
        </FlexboxGrid>
    )
}

const GridItem = ({children, ...props}) => (
    <FlexboxGrid.Item componentClass={Col} colspan={24} {...props}>
        {children}
    </FlexboxGrid.Item>
)

const DetailLink = ({id, label, modelId = "accounting/ledger-account"}) => (
    <StatsLabel label={{id, objectLabel: label, modelId, displayView: modelId}}/>
)
