import {hot} from "react-hot-loader"
import React, {useEffect} from "react"
import {Provider, useDispatch} from "react-redux"
import {IntlProvider, Loader} from "rsuite"
import moment from "moment-timezone"
import store from "../store/store"
import {useAccountingBaseDataQuery} from "./store/accounting-api"
import {setContext} from "./store/entries-slice"

const date = {
    sunday: 'So',
    monday: 'Mo',
    tuesday: 'Di',
    wednesday: 'Mi',
    thursday: 'Do',
    friday: 'Fr',
    saturday: 'Sa',
    ok: 'OK',
    today: 'heute',
    yesterday: 'gestern',
    formattedMonthPattern: 'MMM YYYY',
    May: 'Mai',
    Dec: 'Dez'
}
const deDE = {
    Table: {
        loading: "Laden …"
    },
    Picker: {
        placeholder: "Auswählen"
    },
    DatePicker: {
        ...date,
        formattedDayPattern: 'DD. MMMM YYYY',
    },
    DateRangePicker: {
        ...date,
        last7Days: 'Letzte Woche',
        formattedDayPattern: "d.M.YYYY"
    }
}

const formatDate = (data, formatStr) => moment(data).format(formatStr)

const AccountingProvider = ({children, ...props}) => (
    <IntlProvider locale={deDE} formatDate={formatDate}>
        <Provider store={store}>
            <Initializer {...props}>
                {children}
            </Initializer>
        </Provider>
    </IntlProvider>
)
export default hot(module)(AccountingProvider)

function Initializer({children, context, additional}) {
    const dispatch = useDispatch()
    const {isLoading} = useAccountingBaseDataQuery()

    useEffect(
        () => {
            dispatch(setContext(context, additional))
        },
        [context, additional]
    )

    return isLoading ? <Loader/> : children
}
